$d-c1: #2d3446;
$d-c2: #4b5673;
$d-c3: #1b1f2a;
$d-c4: #fff;
$d-c5: rgba(255, 255, 255, 0.35);

$l-c1: #fff;
.ant-dropdown-menu-item-active{
    background-color: #f5f5f5;
}

@mixin bgrcl($theme, $i) {
    background-color: map-get(
        (
            c1: $l-c1,
        ),
        $i
    );
    background-color: map-get(
        (
            drak:
                map-get(
                    (
                        c1: $d-c1,
                        c2: $d-c2,
                        c3: $d-c3,
                        c4: $d-c4,
                        c5: $d-c5,
                    ),
                    $i
                ),
        ),
        $theme
    );
}

@mixin cl($theme, $cl) {
    // $value:#ffff
}

.ant-menu {
    border-right: none;
}
body[theme="drak"] {
    .ant-dropdown-menu-item-active{
        background-color: #f5f5f5;
    }
    .layout-sider-bar {
        background-attachment: fixed;
        background-image: url("//www.gstatic.com/mobilesdk/190424_mobilesdk/nav_nachos@2x.png");
        background-position: left 0 bottom 0;
        background-repeat: no-repeat;
        background-size: 256px 556px;

        @include bgrcl(drak, c1);
        .ant-layout-sider-trigger {
            background-color: #22374a;
            color: #fff;
        }

        .ant-menu-item:active,
        .ant-menu-submenu-title:active {
            @include bgrcl(drak, c1);
        }
        .ant-menu,
        .ant-menu-sub,
        .ant-menu .ant-menu-sub {
            color: rgba(255, 255, 255, 0.65);
            // @include bgrcl(drak, c1);
            background-color: #2d344600;
        }
        .ant-menu .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
        .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
        .ant-menu .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
        .ant-menu .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
        .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
        .ant-menu .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
            @include bgrcl(drak, c4);
        }
        .ant-menu-inline.ant-menu-sub {
            // @include bgrcl(drak, c2);
            background-color: #4b56733b;
        }
        .ant-menu-horizontal > .ant-menu-item:hover {
            @include bgrcl(drak, c3);
        }
        .ant-menu-item,
        .ant-menu-item-group-title,
        .ant-menu-item > a,
        .ant-menu-item > span > a,
        .ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title {
            color: rgba(255, 255, 255, 0.65);
        }
        .ant-menu-item:hover,
        .ant-menu-item-active,
        .ant-menu-submenu-active,
        .ant-menu-submenu-open,
        .ant-menu-submenu-selected,
        .ant-menu-submenu-title:hover {
            color: #fff;
        }
        .ant-menu-item:hover > a,
        .ant-menu-item-active > a,
        .ant-menu-submenu-active > a,
        .ant-menu-submenu-open > a,
        .ant-menu-submenu-selected > a,
        .ant-menu-submenu-title:hover > a,
        .ant-menu-item:hover > span > a,
        .ant-menu-item-active > span > a,
        .ant-menu-submenu-active > span > a,
        .ant-menu-submenu-open > span > a,
        .ant-menu-submenu-selected > span > a,
        .ant-menu-submenu-title:hover > span > a {
            color: #fff;
        }
        .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
        .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
        .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
        .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
        .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
        .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
        .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
        .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
        .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
        .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
        .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
        .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
        .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
        .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
        .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
        .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
        .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
        .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
        .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
        .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
        .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
        .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
        .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
        .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before {
            @include bgrcl(drak, c4);
        }
        .ant-menu.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
            @include bgrcl(drak, c3);
        }
        .ant-menu-item-selected{
            color: #fff;
        }
        .ant-menu-item-selected > a,
        .ant-menu-item-selected > span > a,
        .ant-menu-item-selected > a:hover,
        .ant-menu-item-selected > span > a:hover,
        .ant-menu-inline-collapsed>.ant-menu-submenu:hover>.ant-menu-submenu-title {
            color: #fff;
        }
        .ant-menu-item-selected .ant-menu-item-icon,
        .ant-menu-item-selected .anticon {
            color: #fff;
        }
        .ant-menu-item-selected .ant-menu-item-icon + span,
        .ant-menu-item-selected .anticon + span {
            color: #fff;
        }
        .ant-menu .ant-menu-item-selected,
        .ant-menu-submenu-popup .ant-menu-item-selected {
            @include bgrcl(drak, c3);
        }
        .ant-menu-item-disabled,
        .ant-menu-submenu-disabled,
        .ant-menu-item-disabled > a,
        .ant-menu-submenu-disabled > a,
        .ant-menu-item-disabled > span > a,
        .ant-menu-submenu-disabled > span > a {
            color: rgba(255, 255, 255, 0.35) !important;
        }
        .ant-menu-item-disabled > .ant-menu-submenu-title,
        .ant-menu-submenu-disabled > .ant-menu-submenu-title {
            color: rgba(255, 255, 255, 0.35) !important;
        }
        .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
        .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
        .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
        .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
            @include bgrcl(drak, c5);
        }
        .ant-menu-item-group-title{
            @include bgrcl(drak, c2);
            color: #bcc6dd;
        }
        .ant-menu-item-divider{
            @include bgrcl(drak, c5);
        }
    }
    
}

.layout-sider-bar {
    z-index: 1;
    @include bgrcl(light, c1);
    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.25);
    .ant-layout-sider-trigger {
        background-color: #e6e6e6;
        color: #002140;
    }
    .ant-menu-inline-collapsed>.ant-menu-item,  .ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title{
        color: #000000b3;
    }
}
