.loading-bar, .loading-bar>div {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 9999;
}

.loading-bar>div>.Loading__loading___1m_fZ>.Loading__bar___21yOt {
	height: 2px;
}

.loading-bar>.css-qegm23 {
	border-width: 1px;
	margin: 5px 0 0 5px;
}