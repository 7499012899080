@for $i from 0 through 1000 {
    // Margin
    .mt-#{$i} {
        margin-top: #{$i}px;
    }
    .mr-#{$i} {
        margin-right: #{$i}px;
    }
    .mb-#{$i} {
        margin-bottom: #{$i}px;
    }
    .ml-#{$i} {
        margin-left: #{$i}px;
    }
    .m-#{$i} {
        margin: #{$i}px;
    }
    .mx-#{$i} {
        margin-left: #{$i}px;
        margin-right: #{$i}px;
    }
    .my-#{$i} {
        margin-top: #{$i}px;
        margin-bottom: #{$i}px;
    }

    // Padding
    .pt-#{$i} {
        padding-top: #{$i}px;
    }
    .pr-#{$i} {
        padding-right: #{$i}px;
    }
    .pb-#{$i} {
        padding-bottom: #{$i}px;
    }
    .pl-#{$i} {
        padding-left: #{$i}px;
    }
    .p-#{$i} {
        padding: #{$i}px;
    }
    .px-#{$i} {
        padding-left: #{$i}px;
        padding-right: #{$i}px;
    }
    .py-#{$i} {
        padding-top: #{$i}px;
        padding-bottom: #{$i}px;
    }
}